
export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoC0/Vt9n7Mygx7VButHQDeV8K/Qtn7XuHDA6mysgnOGI82nLY+aLoCcCA634cVcPKKZNeqLrFOebnQoIjtigITXyzWiulVljsosxcE2eZIgablNZ459ilQdgopjkwzSkE5pcukke4/sRRSu+W1HcMuaI/LseinuSTh2f9IHxB+cy2UuU/uhEId8OsU+TWp90s5V1UU4Z2p5iLm4kd8fe/uTZ6Ro0RZ3+H8H2JyGAjdnFoca/lp3koR6h3kon+6odj+n0aYa12ISB3Dd0yOXte3rAKJ7gBGl8fOhjHHm/P+yGksjGqq/fEJQrcPd8thDCl5gWDpMq+7xxlIfqNLeTwQIDAQAB
-----END PUBLIC KEY-----`
export let logoDescList = [
    {name:'Call',pecent:'21.14%',num:'+2.1'},
    {name:'Fold',pecent:'35.22%',num:'0.0'},
    {name:'Raise to 2.0',pecent:'2.26%',num:'+2.1'},
    {name:'Raise to 2.5',pecent:'19.92%',num:'+2.1'},
    {name:'All in',pecent:'21.46%',num:'+1.7'},
]

// reviews
export let reviewsList = [
    {
      url:require('@/assets/images/reviews/reviews1.png'),
      desc:`“GTOMaster is a resource I use almost on a daily basis. It's a great tool for quickly finding preflop equilibrium solutions and ranges.”`,
      name1:`Artem 'Vea' Vezhenkov`,
      name2:`SVschool Founder`
    },
    {
      url:require('@/assets/images/reviews/reviews2.png'),
      desc:`“GTOMaster is one of the best game development services to hit the market in recent times. With it, the convenience and speed of extracting information and getting answers to questions have increased many times over, which makes the learning process itself much more comfortable and enjoyable. GTOMaster is the modern Aladdin lamp in the poker world :)”`,
      name1:`Grigory 'GregXX' Rodin`,
      name2:`MTT Expert`
    },
    {
      url:require('@/assets/images/reviews/reviews3.png'),
      desc:`“Very smart analyzer of loaded hands. Nowhere have I seen a faster one with such high-quality calculations. Maximum user friendly application both in terms of interface and updates. Just think of a feature.. Wow.. and it's already implemented. Definitely great team work.”`,
      name1:`Artem 'Tema2010' Morgunov`,
      name2:`Spin&Go Coach and Player`
    },
    {
      url:require('@/assets/images/reviews/reviews4.png'),
      desc:`“GTOMaster is the best software for finding GTO-oriented RTAs and bots. The ΔEV parameter shows the similarity of the player's strategy with the optimal one and highlights those who do not make mistakes. A real revolution in the fight for the fairness of the game.”`,
      name1:`Andrei 'TylerRM' Streltsou`,
      name2:`MTT Coach and Player`
    }
]

// 
export let carouselList = [
    {url:require('@/assets/images/features_img1.png')},
    {url:require('@/assets/images/features_img2.png')},
    {url:require('@/assets/images/features_img3.png')},
    {url:require('@/assets/images/features_img4.png')},
    {url:require('@/assets/images/features_img5.png')},
]

// hands
export let handsCarouselList = [
    {url:require('@/assets/images/hands_img1.png')},
    {url:require('@/assets/images/hands_img2.png')},
    {url:require('@/assets/images/hands_img3.png')},
    {url:require('@/assets/images/hands_img4.png')},
    {url:require('@/assets/images/hands_img5.png')},
]

// 品牌
export let brandList = [
    {class:'poker-stars',url:require('@/assets/images/brand/poker-stars.svg')},
    {class:'party-poker',url:require('@/assets/images/brand/party-poker.svg')},
    {class:'wpn',url:require('@/assets/images/brand/wpn.svg')},
    {class:'ipoker',url:require('@/assets/images/brand/ipoker.svg')},
    {class:'full-tilt-poker',url:require('@/assets/images/brand/full-tilt-poker.svg')},
    {class:'poker888',url:require('@/assets/images/brand/888-poker.svg')},
    {class:'winamax',url:require('@/assets/images/brand/winamax.svg')},
    {class:'pppoker',url:require('@/assets/images/brand/pppoker.svg')},
    {class:'ignition',url:require('@/assets/images/brand/ignition.svg')},
    {class:'bovada',url:require('@/assets/images/brand/bovada.svg')},
]

// 价格
export let priceList = [
    {
      name:'SPIN&GO',
      cardList:[
        {
          title:'NL50',
          subTitle:'ANALYZE & VIEW',
          stack:'5 – 25 BB',
        },
        {
          title:'BTNvsSB',
          subTitle:'VIEW',
          stack:'8 – 25 BB',
          color:'green'
        },
        {
          title:'SBvsBB No Limp',
          subTitle:'VIEW',
          stack:'5 – 25 BB',
          color:'green'
        },
        {
          title:'HU',
          subTitle:'ANALYZE & VIEW',
          stack:' 5 – 30 BB'
        },{
          title:'SBvsBB',
          subTitle:'ANALYZE & VIEW',
          stack:'5 – 25 BB'
        }
      ],
      descList:[
        {
          textList:[
            {
              text:'This library contains GTO poker solutions for Spin&Go and HU Hyper Turbo SnG disciplines and covers 5-30bb stacks depth and all possible postflop runouts. The solutions were calculated using сutting edge GTO solving algorithms developed by '
            },
            {
              text:'SimplePoker',isStrong: true
            },
            {
              text:' to achieve high accuracy.',
            }
          ],
        },
        {
          liList:[
            'Compare your actions vs GTO by using our purpose-built reports',
            'Identify leaks in your game',
            'Identify your opponents’ leaks so you can maximise your EV',
          ]
        }
      ],
    },
    {
      name:'HU ANTE',
      cardList:[
        {
          title:'HU Ante',
          subTitle:'ANALYZE & VIEW',
          stack:'5 – 40 BB',
        },
      ],
      descList:[
        {
          text:'This library was developed specially for MTT and SNG players who want to improve their heads-up skill.'
        },
        {
          text:'You can view and analyze heads-up strategy with ante. Ante rounding helps to upload hands with ante 8-15% but for analysis basic solutions with 12% ante are used.'
        },
        {
          text:'If you want to improve your knowledge in heads-up with ante and give no chance to your opponent’s at the most important phase of tournaments, you have to try it.'
        },
        {
          textList:[
            {
              text:'The solutions were calculated using сutting edge GTO solving algorithms developed by '
            },
            {
              text:'SimplePoker',isStrong: true
            },
            {
              text:' to achieve high accuracy.',
            }
          ],
        },
      ]
    },
    {
      name:'MTT',
      isFree: true,
      cardList:[
        {
          title:'MTT 8-max',
          subTitle:'VIEW',
          stack:'40 – 100 BB',
          color:'green'
        },
        {
          title:'MTT 9-max',
          subTitle:'VIEW',
          stack:'15 – 30 BB',
          color:'green'
        },
      ],
      descList:[
        {
          text:'This library includes GTO solutions for 8-max and 9-max MTTs, including major 2-way postflop spots.'
        },
        {
          textList:[
            {
              text:'Preflop strategies were solved using '
            },
            {
              text:'Simple Preflop Holdem',isStrong: true
            },
            {
              text:', postflop strategies using the most accurate ',
            },
            {
              text:'Simple Postflop',isStrong: true
            },
            {
              text:' algorithms. Both preflops and postflops are calculated precisely.'
            },
          ],
        },
        {
          text:'MTT library is available in Viewer, Trainer and Instahand tool.'
        },
      ]
    },
    {
      name:'HU CASH',
      isFree: true,
      cardList:[
        {
          title:'HU Cash',
          subTitle:'VIEW',
          stack:'50 – 100 BB',
          color:'green'
        },
      ],
      descList:[
        {
          textList:[
            {
              text:'The format of HU matches has always been, is and will remain popular in poker among players who have decided to measure their skills and show who is the king of the game. In the era of such matches as Doug Polk vs Daniel Negreanu, Limitless vs Stefan11222, Limitless vs Fedor Holz, Daniel Negreanu vs Phil Hellmuth, '
            },
            {
              text:'Simple Poker',isStrong: true
            },
            {
              text:'has prepared a gift especially for the community – calculated strategies for HU Cash 50-100bb, both preflop and postflop!',
            }
          ],
        },
        {
          textList:[
            {
              text:'Preflop strategies were calculated using '
            },
            {
              text:'Simple Preflop Holdem',isStrong: true
            },
            {
              text:' with the most advanced bucketing (10k/100k/100k of buckets per postflop street) over a very large number of iterations. The preflop strategy was calculated for a chipEV environment (excluding rake), since the rake is extremely low at high limits.',
            }
          ],
        },
        {
          textList:[
            {
              text:'Postflop strategies are solved on all kinds of existing flops, at high accuracy, using the '
            },
            {
              text:'Simple Postflop',isStrong: true
            },
            {
              text:' GTO solver.',
            }
          ],
        },
        {
          text:'Study the optimal strategies for HU Cash and analyze HU matches of professionals from the GTO perspective absolutely free!'
        },
      ]
    },
    {
      name:'6-MAX CASH',
      isFree: true,
      cardList:[
        {
          title:'NL50',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },
        {
          title:'NL100',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },
        {
          title:'NL100 Tune',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },
        {
          title:'NL200',
          subTitle:'VIEW',
          stack:' 5 – 30 BB',
          color:'green'
        },{
          title:'NL500',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },{
          title:'NL500 Tune',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },{
          title:'NL1000',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        },{
          title:'ChipEV',
          subTitle:'VIEW',
          stack:'40 – 200 BB',
          color:'green'
        }
      ],
      descList:[
        {
          textList:[
            {
              text:'6-Max Cash library includes GTO solutions for 6-max cash game from preflop to river with almost all possible spots solved for 100bb stack depth. All spots are solved on all possible boards and runouts. The structure of game trees is designed by professional high stakes players and all branches of trees are worked out in detail. Preflop ranges are calculated using '
            },
            {
              text:'Simple Preflop Holdem',isStrong: true
            },
            {
              text:' for NL500 rake of PokerStars. Postflop strategies are calculated in ChipEV model with very high accuracy using the lastest and the most advanced solving algorithms by ',
            },
            {
              text:'SimplePoker',isStrong: true
            },
            {
              text:'.'
            }
          ],
        },
        {
          text:'At the moment, only Viewer and GTO Trainer are available for 6-Max Cash library. Very soon it will be possible to automatically analyze hands from the most popular poker rooms using this library.'
        },
      ]
    },
]

