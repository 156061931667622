import * as API from './index'
import lywsConfig from '@config/global';

export default {
    // 获取页面数据
    findInfoData: params => {
        return API.GET(`${lywsConfig.gatewayUrl}api/website/info`, params)
    },
    // 注册
    appRegister: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/anonymous/register`, params)
    },
    // 登录
    appLogin: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/anonymous/login`, params)
    },
    // 退出登录
    exitLogin: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/user/exit`, params)
    },
    // 获取个人信息
    getUserInfo: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/user/getUserInfo`, params)
    },
    // 忘记密码获取旧密码
    forgetPass: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/anonymous/forgetPass`, params)
    },
    // 重置密码
    updatePass: params => {
        return API.POST(`${lywsConfig.gatewayUrl}app/user/updatePass`, params)
    },
}